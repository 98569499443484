<template>
	<v-app style="background-color: var(--tg-theme-bg-color)">
		<!-- <v-app-bar
			app
			dense
			color="primary"
			dark
			elevate-on-scroll
			hide-on-scroll
		>
			<div class="d-flex text-h4 align-center">
				PVG
			</div>

		</v-app-bar> -->

		<v-main v-if="isLoaded">
			<router-view/>
		</v-main>
	</v-app>
</template>

<script>
// import axios from 'axios';
import store from "@/store";

export default {
	name: 'App',

	data: () => ({
		isLoaded: false,
	}),
	async created () {
		let tg = window.Telegram.WebApp;
		
		this.$vuetify.theme.dark = tg.colorScheme !== "light";
		/* eslint-disable */

		await store.dispatch('getData').catch(error => {
			if (this.$route.name != 'error') {
				this.$router.push({ name: 'error' });
			}
			this.isLoaded = true;
			tg.showAlert('Ошибка авторизации');
			tg.close();
		});

		const storage = tg.CloudStorage;
		const keys = [
			'order',
			'stage',
			'productId',
			'action',
		];
		const text = tg.MainButton.text;
		tg.MainButton.showProgress();
		tg.MainButton.setText('Загрузка данных...');
		for (const key of keys) {
			await storage.getItem(key, (e, v) => this.storageHandler(e, v, key))
		}
		tg.MainButton.hideProgress();

		this.isLoaded = true;
	},
	methods: {
		test (data) {
			alert(data);
		},
		storageHandler (error, value, key) {
			key = key.charAt(0).toUpperCase() + key.slice(1);
			try {
				value = JSON.parse(value);
			} catch (e) {
				value = value;
			}
			store.dispatch(`set${key}`, value);
		}
	}
};
</script>

<style>
	:root {
		--v-primary-base: var(--tg-theme-button-color) !important;
	}

	.v-data-table__mobile-table-row {
		display: flex !important;
		border-bottom: thin solid rgba(0,0,0,.12) !important;
	}
	.theme--dark.v-data-table .v-data-table__mobile-rable-row {
		border-bottom: thin solid hsla(0,0%,100%,.12) !important;
	}
	.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child, .theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row), .theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:last-child, .theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row), .theme--dark.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th,
	.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
		border-bottom: 0 !important;
	}

	.v-application--is-ltr .v-data-footer__pagination {
		margin-left: 14px !important;
		margin-right: 14px !important;
	}
	.v-application--is-ltr .v-data-footer__select .v-select {
		margin-left: 14px !important;
	}
</style>
