import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/es5/locale/ru'

Vue.use(Vuetify);

// const tg = window.Telegram.WebApp;

export default new Vuetify({
	lang: {
		locales: { ru },
		current: 'ru',
	},
	theme: {
		options: {
			customProperties: true,
		}
	},
});
