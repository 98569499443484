import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import {
	GET_DATA,
	SET_ORDER,
	SET_STAGE,
	SET_PRODUCT_ID,
	SET_ACTION,
	SET_FILES,
	SET_TYPE,
	UPDATE_UPLOADING_FILE,
	ADD_UPLOADING_FILE,
	SET_AVAIABLE_PRODUCTS
} from './mutations';

import router from '../router';

Vue.use(Vuex)

const tg = window.Telegram.WebApp;
const storage = tg.CloudStorage;

function formatBytes(a,b=2){if(!+a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return`${parseFloat((a/Math.pow(1024,d)).toFixed(c))} ${["Bytes","KiB","MiB","GiB","TiB","PiB","EiB","ZiB","YiB"][d]}`}

export default new Vuex.Store({
	state: {
		productId: null,
		action: 'new',
		type: null,
		order: null,
		stage: null,
		uploadingFiles: [],
		data: null,
		files: null,
		avaiableProducts: [],
	},
	getters: {
		telegram: state => state.telegram,
		order: state => state.order,
		stage: state => state.stage,
		productId: state => state.productId,
		action: state => state.action,
		files: state => state.files,
		type: state => state.type,
		uploadingFiles: state => state.uploadingFiles,
		avaiableProducts: state => state.avaiableProducts,
		data: state => state.data,
	},
	mutations: {
		[SET_TYPE] (state, type) {
			state.type = type;
		},
		[SET_PRODUCT_ID] (state, id) {
			state.productId = id;
			storage.setItem('productId', id);
		},
		[SET_ACTION] (state, action) {
			state.action = action;
			storage.setItem('action', action);
		},
		[SET_FILES] (state, files) {
			state.files = files;
		},
		[SET_AVAIABLE_PRODUCTS] (state, products) {
			state.avaiableProducts = products;
		},
		[SET_ORDER] (state, id) {
			if (!id) {
				state.order = id;
			} else {
				state.order = state.data.orders.find(order => order.order_id == id);
			}
			storage.setItem('order', id);
		},
		[SET_STAGE] (state, id) {
			if (!id) {
				state.stage = id;
			} else {
				state.stage = state.data.stages.find(stage => stage.id == id);
			}
			storage.setItem('stage', id);
		},
		[UPDATE_UPLOADING_FILE] (state, file) {
			const index = state.uploadingFiles.findIndex(f => f.title == file.title);
			Vue.set(state.uploadingFiles, index, file);
		},
		[ADD_UPLOADING_FILE] (state, title) {
			state.uploadingFiles.push({
				title: title,
				progress: 0,
				status: "loading",
			});
		},
		[GET_DATA] (state, data) {
			state.data = data;
		},
	},
	actions: {
		async getData ({ commit }) {
			return new Promise((resolve, reject) => {
				tg.MainButton.showProgress();
				tg.MainButton.setText('Авторизация...');
				const data = {
					_auth: tg.initData,
					user: tg.initDataUnsafe.user
					// user: {
					// 	id: 1329736882,
					// 	username: 'avdeevmikhail',
					// }
				}
				axios.post('https://tg.pvg.ru/bot/getData', data).then(response => {
					commit(GET_DATA, response['data']);
					tg.MainButton.hideProgress();
					resolve(1);
				}).catch(error => {
					reject(error);
					console.log(error['response']['data']);
				});
			})
			// HTTP.post('getData', {data}
			// ).then(response => {
			// });
		},
		setOrder ({ commit }, id) {
			commit(SET_ORDER, id);
		},
		setStage ({ commit }, id) {
			commit(SET_STAGE, id);
		},
		setFiles ({ commit }, files) {
			commit(SET_FILES, files);
		},
		setProductId ({ commit }, id) {
			commit(SET_PRODUCT_ID, id);
		},
		getProductId () {

		},
		setAction ({ commit }, action) {
			commit(SET_ACTION, action);
		},
		setType ({ commit }, type) {
			commit(SET_TYPE, type);
		},
		async getAvaiableProducts ({ commit, state, dispatch }) {
			let data = {
				'entityTypeId': 172,
				'filter': {
					'ufCrm38_1692953759': state.stage.id,
					'PARENT_ID_155': state.order.order_id,
				},
				'select': [
					'id',
					'ufCrm38_1692953727',
				],
			}

			let response = await axios.post(
				"https://b24.pvg.ru/rest/1009/1jiyqw8f7eutjzsn/" + 'crm.item.list',
				data,
			);

			const result = response['data'];

			const total = result['total'];
			const items = result['result']['items'];

			if (total == 0) {
				tg.showAlert('Изделий для просмотра не найдено');
				tg.HapticFeedback.impactOccurred('light');
				tg.MainButton.offClick(this.clickHandler);
				router.push({ name: 'stage', params: { back: true } });
			}

			if (total <= 50) {
				commit(SET_AVAIABLE_PRODUCTS, items);
				return;
			}

			const batchesCount = Math.ceil(result['total'] / 50);

			dispatch('batchList', batchesCount);
		},
		async batchList ({ state, commit }, batchesCount) {
			const serialize = function(obj, prefix) {
				var str = [],
					p;
				for (p in obj) {
					if (Object.prototype.hasOwnProperty.call(obj, p)) {
						var k = prefix ? prefix + "[" + p + "]" : p,
							v = obj[p];
						str.push((v !== null && typeof v === "object") ?
							serialize(v, k) :
							encodeURIComponent(k) + "=" + encodeURIComponent(v));
					}
				}
				return str.join("&");
			}
			let data = {
				'entityTypeId': 172,
				'filter': {
					'ufCrm38_1692953759': state.stage.id,
					'PARENT_ID_155': state.order.order_id,
				},
				'select': [
					'id',
					'ufCrm38_1692953727',
				],
			}
			const batchList = [];
			let batchCount = 0;
			let batchInnerCounter = 0;
			let start = 0;
			for ( let i = 0; i < batchesCount; i++ ) {
				if ( batchInnerCounter === 50 ) {
					batchCount++;
					batchInnerCounter = 0;
				}

				data['start'] = start;
				if (!batchList[batchCount]) {
					batchList[batchCount] = [];
				}
				batchList[batchCount].push(serialize(data));
				batchInnerCounter++;
				start += 50;
			}

			const response = [];
			for (const batch of batchList) {
				const result = await axios.post(
					"https://b24.pvg.ru/rest/1009/1jiyqw8f7eutjzsn/" + 'batch',
					{
						'halt': 0,
						'cmd': batch.map(url => 'crm.item.list?' + url),
					}
				)

				result['data']['result']['result'].forEach(request => {
					response.push(...request['items']);
				});
			}

			commit(SET_AVAIABLE_PRODUCTS, response);
		},
		async getProduct ({ state }) {
			let data = {
				'entityTypeId': 172,
				'filter': {
					'ufCrm38_1692953727': state.productId,
					'ufCrm38_1692953759': state.stage.id,
					'PARENT_ID_155': state.order.order_id,
				},
				'select': [
					"id"
				]
			}

			let response = await axios.post(
				"https://b24.pvg.ru/rest/1009/1jiyqw8f7eutjzsn/" + 'crm.item.list',
				data,
			);
			const items = response['data']['result']['items'];

			if (items.length == 0) {
				return false;
			}
			return items[0];
		},
		async sendFiles ({ commit, state, dispatch }, { files }) {
			tg.isClosingConfirmationEnabled = true;
			dispatch('occupy', true);
			let formData = new FormData();
			let filesText = "";
			for( var i = 0; i < files.length; i++ ){
				formData.append(`files[]`, files[i]);
				filesText += `${i + 1}. ${files[i].name} (${formatBytes(files[i].size)})\n`
			}

			const order = state.order;
			const stage = state.stage;
			const productId = state.productId;

			const title = `ТЗ №${state.order.id_is}, изделие ${state.productId}, ${new Date().toLocaleTimeString()}`;

			commit(ADD_UPLOADING_FILE, title);

			let entity_id, data, response;
			let action = state.action;
			/* eslint-disable */
			if (action == "new") {
				data = {
					'entityTypeId': 172,
					'fields': {
						"TITLE": `ТЗ №${state.order.id_is} / ${state.stage.title} / №${state.productId}`,
						"ASSIGNED_BY_ID": state.data.user_id,
						"ufCrm38_1692953727": state.productId,
						"ufCrm38_1692953759": state.stage.id,
						"PARENT_ID_155": state.order.order_id,
					},
				}

				response = await axios.post(
					"https://b24.pvg.ru/rest/1009/1jiyqw8f7eutjzsn/" + 'crm.item.add',
					data
				)

				entity_id = response['data']['result']['item']['id'];
				action = "add";
			} else {
				entity_id = await dispatch('getProduct');
				entity_id = entity_id['id'];
			}

			data = {
				'entityTypeId': 172,
				'id': entity_id,
				'fields': {
					"ASSIGNED_BY_ID": state.data.user_id,
				},
			}

			response = await axios.post(
				"https://b24.pvg.ru/rest/1009/1jiyqw8f7eutjzsn/" + 'crm.item.update',
				data
			)

			data = {
				"entityTypeId": 172,
				"entityId": entity_id,
				"field": "UF_CRM_38_1692953741",
				"mode": action,
			}

			formData.append('entityTypeId', 172);
			formData.append('entityID', entity_id);
			formData.append('field', 'UF_CRM_38_1692953741');
			formData.append('mode', action);

			response = await axios.post(
				"https://b24.pvg.ru/" + 'files/load/',
				formData,
				{
					// auth: {
					//   username: 'admin',
					//   password: 'zMTQrE@?y09%CmI'
					// },
					headers: {
						'Content-Type': 'multipart/form-data'
					},
					onUploadProgress: function(progressEvent) {
						const progress = parseInt(Math.round(( progressEvent.loaded / progressEvent.total) * 100));
						commit('UPDATE_UPLOADING_FILE', {
							title: title,
							progress: progress,
							status: "loading",
						})
					}
				}
			)
			.then(response => {
				commit(UPDATE_UPLOADING_FILE, {
					title: title,
					progress: 100,
					status: "complete",
				});
				axios.post('https://tg.pvg.ru/bot/occupy', {
					order_id: order.order_id,
					stage: stage.id,
					product_id: productId,
					occupy: false,
					_auth: tg.initData,
				});
				axios.post("https://tg.pvg.ru/bot/sendMessage", {
					_auth: tg.initData,
					user_id: tg.initDataUnsafe.user.id,
					message: `Внесены изменения в ТЗ №${order.id_is}, контрольная точка ${stage.title}, изделие №${productId}:\n` + filesText
				});
				let check = true;
				state.uploadingFiles.forEach(u => {
					if (u['status'] == "loading") {
						check = false;
					}
				});
				if (check) {
					tg.isClosingConfirmationEnabled = false;
				}
			})
			.catch(function(error) {
				alert(error);
				console.log(error);
				commit(UPDATE_UPLOADING_FILE, {
					title: title,
					progress: 100,
					status: "error",
				})
			});
		},
		async occupy ({ commit, state }, occupy) {
			return new Promise(resolve => {
				axios.post('https://tg.pvg.ru/bot/occupy', {
					order_id: state.order.order_id,
					stage: state.stage.id,
					product_id: state.productId,
					occupy: occupy,
					_auth: tg.initData,
				}).then(response => {
					resolve(response['data']['occupied']);
				}).catch(error => {
					tg.showAlert('Ошибка проверки изделия');
				});
			});
		},
		async getFiles ({ commit, state, dispatch }) {
			return new Promise(async resolve => {
				const entity_id = await dispatch('getProduct');
				const data = {
					"entityTypeId": 172,
					"filter": {
						"id": entity_id,
						// "id": 822,
						// "ufCrm22_1637244165": state.order.id_is,
						// "ufCrm22_1637244165": 55595,
					},
					"select": [
						"id",
						"ufCrm38_1692953741",
						// state.stage.id,
						// 'UF_CRM_22_1692362019',
					]
				}

				axios.post(
					"https://b24.pvg.ru/rest/1009/1jiyqw8f7eutjzsn/" + 'crm.item.list',
					data,
				).then(async response => {
					const order = response['data']["result"]["items"][0];
					const files = order["ufCrm38_1692953741"];
					const urls = [];
					for (const file of files) {
						const data = {
							"file_id": file["id"],
						}

						let formData = new FormData();
						formData.append('file_id', file['id']);
						const url = await axios.post(
							"https://b24.pvg.ru/" + 'files/get/',
							formData,
							// {
							// 	auth: {
							// 	  username: 'admin',
							// 	  password: 'zMTQrE@?y09%CmI'
							// 	},
							// }
						);
						urls.push("https://b24.pvg.ru/" + url['data'].replace(/^\//, ""));
					}
					resolve(urls);
				});
			});
		},
		resetData ({ dispatch }) {
			const keys = [
				'type',
				'order',
				'stage',
				'productId',
				'action',
				'files',
			];
			keys.forEach(key => {
				key = key.charAt(0).toUpperCase() + key.slice(1);
				dispatch(`set${key}`, null);
			});
		}
	},
	modules: {
	}
})
