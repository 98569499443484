import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/order',
    name: 'order',
    props: true,
    component: function () {
      return import(/* webpackChunkName: "order" */ '../views/OrderView.vue')
    }
  },
  {
    path: '/stage',
    name: 'stage',
    props: true,
    component: function () {
      return import(/* webpackChunkName: "stage" */ '../views/StageView.vue')
    }
  },
  {
    path: '/product',
    name: 'product',
    component: function () {
      return import(/* webpackChunkName: "product" */ '../views/ProductView.vue')
    }
  },
  {
    path: '/files',
    name: 'files',
    component: function () {
      return import(/* webpackChunkName: "files" */ '../views/FilesView.vue')
    }
  },
  {
    path: '/preview',
    name: 'preview',
    component: function () {
      return import(/* webpackChunkName: "preview" */ '../views/PreviewView.vue')
    }
  },
  {
    path: '/error',
    name: 'error',
    component: function () {
      return import(/* webpackChunkName: "error" */ '../views/ErrorView.vue')
    },
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
