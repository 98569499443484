import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"text-center"},[_c(VRow,[_c(VCol,{staticClass:"d-flex flex-column"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.clickHandler}},[_vm._v("Загрузить фото или видео")])],1)],1),_c(VRow,[_c(VCol,{staticClass:"d-flex flex-column"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.previewClickHandler}},[_vm._v("Посмотреть фото или видео")])],1)],1),_c(VRow,[_c(VCol,[_c('div',[_vm._v("Здесь будут отображаться текущие загрузки")])])],1),(_vm.uploadingFiles.length > 0)?_vm._l((_vm.uploadingFiles),function(file,index){return _c(VRow,{key:index},[_c(VCol,[_c('div',{staticClass:"mb-2"},[_vm._v(_vm._s(file.title))]),_c(VProgressLinear,{attrs:{"color":_vm.getColor(file.status)},model:{value:(file.progress),callback:function ($$v) {_vm.$set(file, "progress", $$v)},expression:"file.progress"}})],1)],1)}):_c(VRow,[_c(VCol,[_c('div',{staticClass:"text--secondary"},[_vm._v("Текущих загрузок не найдено")])])],1),_c(VRow,[_c(VCol,[_vm._v(" Версия 3.20 ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }