<template>
	<v-container class="text-center">
		<v-row>
			<v-col class="d-flex flex-column">
				<v-btn @click="clickHandler" color="primary">Загрузить фото или видео</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex flex-column">
				<v-btn @click="previewClickHandler" color="primary">Посмотреть фото или видео</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<div>Здесь будут отображаться текущие загрузки</div>
			</v-col>
		</v-row>
		<template v-if="uploadingFiles.length > 0">
			<v-row v-for="(file, index) in uploadingFiles" :key="index">
				<v-col>
					<div class="mb-2">{{ file.title }}</div>
					<v-progress-linear :color="getColor(file.status)" v-model="file.progress"></v-progress-linear>
				</v-col>
			</v-row>
		</template>
		<v-row v-else>
			<v-col>
				<div class="text--secondary">Текущих загрузок не найдено</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				Версия 3.20
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store';
const tg = window.Telegram.WebApp;

export default {
	name: 'Home',
	created () {
		if (this.uploadingFiles.findIndex(file => file.status == "loading") != -1) {
			tg.MainButton.setText("Идёт загрузка...");
			tg.MainButton.showProgress();
		} else {
			tg.MainButton.hide();
		}
		if (tg.BackButton.isVisible) {
			tg.BackButton.hide();
		}
	},
	computed: {
		...mapGetters(['uploadingFiles']),
	},
	watch: {
		uploadingFiles () {
			if (this.uploadingFiles.findIndex(file => file.status == "loading") == -1) {
				tg.MainButton.hide();
				tg.MainButton.hideProgress();
			}
		}
	},
	methods: {
		getColor (status) {
			if (status == "complete") return "success";
			if (status == "error") return status;
			return "primary";
		},
		clickHandler () {
			tg.HapticFeedback.impactOccurred('light');
			tg.MainButton.offClick(this.clickHandler);
			store.dispatch('setType', 'edit');
			this.$router.push({ name: 'order' });
		},
		previewClickHandler () {
			tg.HapticFeedback.impactOccurred('light');
			tg.MainButton.offClick(this.clickHandler);
			store.dispatch('setType', 'preview');
			this.$router.push({ name: 'order' });
		},
	},
}
</script>
